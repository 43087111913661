/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "./bootstrap.min.css"
import "./layout.css"
import StickyBottom from "../components/stickybottom"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ReactGA from "react-ga"
import Img from "gatsby-image"

ReactGA.initialize("UA-142617276-1")

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "888king.png" }) {
          childImageSharp {
            fluid(maxWidth: 450) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          console.log('ddd'); console.log('data:',data);
          <header>
            <div className="container">
              <div className="row pt-3 pb-2">
                <div className="col-lg-3 col-md-1" />
                <div className="col-sm-12 col-lg-6 col-md-9">
                  <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </header>
          <main>{children}</main>
          <footer className="py-3 mb-4">
            <StickyBottom />
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
