import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Download from "../components/download"
import ReactGA from "react-ga"

ReactGA.pageview("/index.html")

const IndexPage = () => (
  <Layout>
    <SEO
      title="888-King"
      keywords={[
        `888-King apk`,
        `888-King ios`,
        `888-King apk free download`,
        `download 888-King apk`,
        `888-King apk download for android mobile`,
        `888-King app`,
        `888-King apk download`,
        `888-King download android`,
        `888-King download apk`,
        `888-King download`,
        `888-King Malaysia`,
      ]}
    />
    <Download />
  </Layout>
)

export default IndexPage
